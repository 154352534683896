<template>
  <div class="bigDiv">
    <img :src="IMGuRL" alt="" class="imgs" />
  </div>
</template>

<script>
import { getTgOrderInfo } from "../../api/components";
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      trade_no: "",
      IMGuRL: "",
    };
  },
  methods: {
    async getTgOrderInfo() {
      try {
        const query = {
          trade_no: this.trade_no,
          is_qrcode: 1,
        };
        const { data } = await getTgOrderInfo(query);
        this.IMGuRL = data.data.img_url;
        // 请求完成后跳转链接
        // window.location.href = data.data.link_url;
      } catch (error) {
        console.error("Error fetching order info:", error);
      } finally {
        this.loading = false;
        this.$toast.clear();
      }
    },
  },
  mounted() {},
  created() {
    this.trade_no = this.$route.query.trade_no;
    this.$toast.loading({
      duration: 0,
      forbidClick: true,
      message: "正在加载,请稍等...",
    });
    this.getTgOrderInfo();
  },
  watch: {},
  computed: {},
  filters: {},
};
</script>

<style scoped lang="less">
.bigDiv {
  width: 375px;
  height: 812px;
  background-image: url("https://oss.hshwhkj.com/hshcrm/image/2024/09/23/172707971895830350.png");
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  .imgs {
    width: 200px;
    height: 200px;
    object-fit: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -95%);
    z-index: 999;
  }
}
</style>
